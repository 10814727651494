import React, { useEffect, useState } from 'react'
import styles from './policy.module.css'
export default function Policy() {
    const [header, setHeader] = useState(0)
    useEffect(() => {
        const cb = () => {
            const header = document.getElementById('header')
            setHeader(header?.clientHeight)
        }
        document.addEventListener('resize', cb)
        return () => {
            document.removeEventListener('resize', cb)
        }
    }, [])
    return (
        <div style={{ height: `calc(100vh - ${header + 80}px)` }} className={styles.background} >
            <div style={{ width: '1010px' } } className={styles.inner} >

                <h1>RippleTV Privacy Policy</h1>
                <br />
                <p><strong>Effective Date:</strong> 08/06/2023</p>
                <br />
                <p>Thank you for using RippleTV! This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our OTT (Over-The-Top) solution app named RippleTV ("App"), available on the Play Market and App Store. Please read this Privacy Policy carefully to understand our practices regarding your personal information.</p>
                <br />
                <h2>1. Information We Collect</h2>
                <br />
                <p><strong>1.1. Personal Information:</strong> We may collect personal information that you provide directly to us when you use the App, such as your name, email address, and contact details.</p>
                <br />
                <p><strong>1.2. Usage Information:</strong> We may automatically collect certain information about your use of the App, including your device information (e.g., device type, operating system), log data (e.g., IP address, access times), and App usage statistics.</p>
                <br />
                <p><strong>1.3. Cookies and Similar Technologies:</strong> We may use cookies and similar technologies to collect information about your usage patterns and enhance your experience on the App. These technologies may collect information such as your IP address, browser type, and browsing behavior.</p>
                <br />
                <h2>2. Use of Information</h2>
                <br />
                <p><strong>2.1. Provide and Improve the App:</strong> We use the collected information to provide and improve the functionality of the App, personalize your experience, and understand how users interact with the App.</p>
                <br />
                <p><strong>2.2. Communication:</strong> We may use your personal information to communicate with you, respond to your inquiries, provide updates or notifications related to the App, and send promotional materials with your consent.</p>
                <br />
                <p><strong>2.3. Analytics and Aggregated Data:</strong> We may use aggregated and anonymized information for analytical purposes, to improve our services, and to generate statistical data regarding the usage of the App.</p>
                <br />
                <h2>3. Disclosure of Information</h2>
                <br />
                <p><strong>3.1. Service Providers:</strong> We may share your personal information with trusted third-party service providers who assist us in operating, analyzing, and improving the App. These service providers are bound by confidentiality obligations and are prohibited from using your personal information for any other purpose.</p>
                <br />
                <p><strong>3.2. Legal Compliance:</strong> We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or government regulations.</p>
                <br />
                <p><strong>3.3. Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email or through a prominent notice on the App of any change in ownership or use of your personal information.</p>
                <br />
                <h2>4. Data Security</h2>
                <br />
                <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security of your information.</p>
                <br />
                <h2>5. Children's Privacy</h2>
                <br />
                <p>The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected personal information from a child under 13, we will promptly delete such information from our records.</p>
                <br />
                <h2>6. Your Choices</h2>
                <br />
                <p>You can control and manage certain information collected by the App through the settings available on your device. You may also choose not to provide certain personal information, but this may limit your ability to use certain features of the App.</p>
                <br />
                <h2>7. Changes to this Privacy Policy</h2>
                <br />
                <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on the App or through other appropriate communication channels. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <h2>8. Contact Us</h2>
                <br />
                <p>If you have any questions, concerns, or suggestions regarding this Privacy Policy or our privacy practices, please contact us at <a href="mailto: info@rippletv.io">info@RippleTV.io</a>.</p>
                <br />
                <p>By using the App, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information as described herein.</p>

            </div>
        </div>
    )
}
