import React, { useEffect, useState } from "react";
import styles from "./section5.module.css";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../utils/constants";
import { ReactComponent as Loader } from '../../../assets/loader.svg'
import { ReactComponent as EmailSent } from '../../../assets/emailsent.svg'

const Section5 = ({ isInView }) => {
  const { t } = useTranslation();

  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    text: "",
  });
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  // const [screenSize, setScreenSize] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = () => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 900) {
  //     return {
  //       transform: ` scale(${screenSize.height / 900}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   }
  // };

  const handleInput = (e) => {
    setInputValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const prop1 = {
    transform: ` scale(${window.innerHeight / 900}) `,
  };
  const prop2 = {
    transform: ` scale(${window.innerHeight / 630}) `,
  };

  const styleResult =
    window.innerWidth > 1400 && window.innerHeight < 900
      ? prop1
      : window.innerWidth < 1400 && window.innerHeight < 630
        ? prop2
        : {};

  const [display, setDisplay] = useState(false)
  useEffect(() => {
    if (isInView) {
      setDisplay("inherit")
    } else {
      setTimeout(() => {
        setDisplay("none")
      }, 600)
    }
  }, [isInView])

  function validateEmail(email) {
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.match(emailPattern)
  }

  const sendEmail = () => {
    
    if(!inputValues.email || !inputValues.name || !inputValues.text){
      setErrors({ reqError: 'All fields are required', noEmail: true })
      return
    }
    if (!validateEmail(inputValues.email)) {
      setErrors({ reqError: "Please provide valid Email", noEmail: true })
      return
    }
    
    const url = API_URL + "/api/v1/web/contacts"
    setLoading(true)
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(inputValues)
    })
      .then(res => {
        if (!res.ok) {
          setErrors(prev=>({...prev, noEmail: true}))
          throw new Error(`HTTP error! Status: ${res.status}`);
      }
        return res.json()
      })
      .then((res) => {
        setLoading(false)
        setErrors({})
        if (res.errorMsg) {
          setErrors({ reqError: res.errorMsg ? res.errorMsg : 'An error occuired, please try again later or' })
          return
        }
        setSuccess(true)
      })
      .catch((error) => {
        setErrors({ reqError: error.errorMsg ? error.errorMsg : 'An error occuired, please try again later or' })
        setLoading(false)
      })
  }

  return (
    <div className={styles.content} style={{ ...styleResult, display: display }}>
      {/* <div className={styles.bgContainer}> */}
      <div className={styles.Contact}>
        <div className={styles.contentInfo}>
          <p><a href="tel:+374-95-215-216">{t(`provider.5.contact1`)}</a></p>
          <p><a href="mailto:info@rippletv.io?subject=Mail to RippleTV from WEB">{t(`provider.5.contact2`)}</a></p>
          <p>{t(`provider.5.contact3`)}</p>
        </div>
      </div>
      <div className={styles.container}>
        {success ? <div className={styles.success} >
          <EmailSent />
          <div className={styles.successTitle}>
            Email sent
          </div>
          <div className={styles.successText} >
            Thank you for reaching out! Your message has been successfully sent.
            <br/>
            We appreciate your interest and will get back to you as soon as possible.
          </div>
        </div>
          :
          <>
            <p>{t(`provider.5.title`)}</p>
            <div className={styles.inputs}>
              <input
                name={"name"}
                placeholder={t(`provider.5.inp1`)}
                type="text"
                onChange={(e) => handleInput(e)}
              />
              <input
                name={"email"}
                placeholder={t(`provider.5.inp2`)}
                type="email"
                onChange={(e) => handleInput(e)}
              />
              <textarea
                name="text"
                placeholder={t(`provider.5.inp3`)}
                onChange={(e) => handleInput(e)}
                maxLength={1000}
              ></textarea>
              {errors.reqError && <p className={styles.error} >{errors.reqError} {!errors.noEmail && <>contact us by email <a href="mailto:info@Rippletv.io"> info@Rippletv.io </a></>}</p>}
              <button className={styles.submit} onClick={sendEmail}>
                <span>
                  {t(`provider.5.btn`)}
                </span>
                &nbsp;
                {loading && <Loader />}
              </button>
            </div>
          </>}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Section5;
