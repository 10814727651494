import React, { useEffect, useMemo, useRef, useState } from "react";

import Section1 from "./section1/Section1";
import Section2 from "./section2/Section2";
import Section3 from "./section3/Section3";
import Section4 from "./section4/Section4";
import {
  animated,
  config,
  useSpring,
  useSpringRef,
  useSprings,
} from "@react-spring/web";
import stylesBack from "./user.module.css";
import { flushSync } from "react-dom";
import Section5 from "../provider/section5/Section5";

const Users = () => {
  const [scrollDir, setScrollDir] = useState(null);
  const [index, setIndex] = useState(0);
  const touchStart = useRef(null);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const section = params.get('section');
    const baseUrl = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, baseUrl);
    if ((+section) > -1) {
      setTimeout(() => {
        scrollFromDots(+section)
      }, 600)
    }
  }, [])

  const springCloseApi1 = useSpringRef();
  const springCloseApi2 = useSpringRef();
  const springCloseApi3 = useSpringRef();
  const springCloseApi4 = useSpringRef();

  const springColor1_1Api = useSpringRef();
  const springColor2_1Api = useSpringRef();
  const springColor1_2Api = useSpringRef();
  const springColor2_2Api = useSpringRef();
  const springColor1_3Api = useSpringRef();
  const springColor2_3Api = useSpringRef();

  const height100 = { height: window.innerWidth < 500 ? "85vh" : "92vh", opacity: 1 };
  const heightNone = { height: "0vh", opacity: 0 };
  const configSection = config.gentle;
  // { mass: 1, tension: 280, friction: 50 }

  const height100Green = { height: "92vh" };
  const heightNoneGreen = { height: "0vh" };

  const configSection1 = {
    ref: springCloseApi1,
    from: height100,
    to: heightNone,
    config: configSection,
  };
  const configSection2 = {
    ref: springCloseApi2,
    from: height100, // mobile 96.5
    to: heightNone,
    config: configSection,
  };
  const configSection3 = {
    ref: springCloseApi3,
    from: height100,
    to: heightNone,
    config: configSection,
  };
  const configSection4 = {
    ref: springCloseApi4,
    from: '82.5vh',
    to: heightNone,
    config: configSection,
  };

  const configColor1_1 = {
    ref: springColor1_1Api,
    from: height100Green,
    to: heightNoneGreen,
    config: { mass: 1000, tension: 280, friction: 50 },
    // delta: 200
  };
  const configColor2_1 = {
    ref: springColor2_1Api,
    from: height100Green,
    to: heightNoneGreen,
    config: { mass: 1000, tension: 280, friction: 50 },
    // delta: 200
  };

  const configColor1_2 = {
    ref: springColor1_2Api,
    from: height100Green,
    to: heightNoneGreen,
    config: { mass: 1000, tension: 280, friction: 50 },
    // delta: 200
  };
  const configColor2_2 = {
    ref: springColor2_2Api,
    from: height100Green,
    to: heightNoneGreen,
    config: { mass: 1000, tension: 280, friction: 50 },
    // delta: 200
  };

  const configColor1_3 = {
    ref: springColor1_3Api,
    from: height100Green,
    to: heightNoneGreen,
    config: { mass: 1000, tension: 280, friction: 50 },
    // delta: 200
  };
  const configColor2_3 = {
    ref: springColor2_3Api,
    from: height100Green,
    to: heightNoneGreen,
    config: { mass: 1000, tension: 280, friction: 50 },
    // delta: 200
  };

  const section1Styles = useSpring({ ...configSection1 });
  const section2Styles = useSpring({ ...configSection2 });
  const section3Styles = useSpring({ ...configSection3 });
  const section4Styles = useSpring({ ...configSection4 });

  const color1_1Styles = useSpring({ ...configColor1_1 });
  const color2_1Styles = useSpring({ ...configColor2_1 });

  const color1_2Styles = useSpring({ ...configColor1_2 });
  const color2_2Styles = useSpring({ ...configColor2_2 });

  const color1_3Styles = useSpring({ ...configColor1_3 });
  const color2_3Styles = useSpring({ ...configColor2_3 });

  const refs = [
    springCloseApi1,
    springCloseApi2,
    springCloseApi3,
    springCloseApi4,
  ];

  const refColors = [
    { firstColor: springColor1_1Api, secondColor: springColor2_1Api },
    { firstColor: springColor1_2Api, secondColor: springColor2_2Api },
    { firstColor: springColor1_3Api, secondColor: springColor2_3Api },
  ];

  const isScrolling = useRef(false);
  const stepsDone = useRef(0)


  const handleScroll = function (e, isTouch) {
    let deltaY = e.deltaY;
    let steps = e.steps;
    if (isTouch) {
      let te = e.changedTouches[0].clientY;
      deltaY = touchStart.current - te;
    }
    if (isScrolling.current && !steps) return;
    const timeShort = steps > 1 ? 50 : 100
    const timeLong = steps > 1 ? 100 : 300
    if (deltaY < 0) {
      // up
      const nextIndex = index - stepsDone.current - 1 < 0 ? index - stepsDone.current : index - stepsDone.current - 1;
      if (nextIndex !== index || steps) {
        isScrolling.current = true;
        refColors[nextIndex].secondColor.start({
          from: heightNoneGreen,
          to: height100Green,
        });
        setTimeout(() => {
          refColors[nextIndex].firstColor.start({
            from: heightNoneGreen,
            to: height100Green,
          });
          setTimeout(() => {
            refs[nextIndex].start({
              from: heightNone,
              to:
                nextIndex === 1
                  ? height100 // mobile 96.5 // pc 91.8
                  : height100,
              onRest: () => {
                isScrolling.current = false
              },
            });
            if (steps && steps > stepsDone.current + 1) {
              stepsDone.current += 1
              handleScroll({ ...e })
            } else {
              stepsDone.current = 0
            }
          }, timeShort);
        }, timeLong);
        setIndex(nextIndex);
      }
    } else if (deltaY > 0) {
      // down
      const nextIndex = index + stepsDone.current + 1 > refs.length - 1 ? index + stepsDone.current : index + stepsDone.current + 1;
      if (nextIndex !== index || steps) {
        isScrolling.current = true;
        refs[index + stepsDone.current].start({
          from: height100,
          to: heightNone,
        });
        setTimeout(() => {
          refColors[index + stepsDone.current].firstColor.start({
            from: height100Green,
            to: heightNoneGreen,
          });
          setTimeout(() => {
            refColors[index + stepsDone.current].secondColor.start({
              from: height100Green,
              to: heightNoneGreen,
              onRest: () => {
                isScrolling.current = false

              },
            });
            if (steps && steps > stepsDone.current + 1) {
              stepsDone.current += 1
              handleScroll({ ...e })
            } else {
              stepsDone.current = 0
            }

          }, timeShort);
        }, timeLong);
        setIndex(nextIndex);
      }
    }
  };

  const sectionNames = [
    'Overview',
    'Features',
    // 'moreInfo',
    'Sign Up',
    'Connect Us'
  ]

  const handleGoToSignUp = () => {
    scrollFromDots(2)
  }

  const handleTouchStart = (e) => {
    touchStart.current = e.targetTouches[0].clientY;
  };

  const scrollFromDots = (changeableIndex) => {
    if (isScrolling.current) return
    if (changeableIndex > index) {
      handleScroll({ deltaY: 1, steps: changeableIndex - index });
    } else if (changeableIndex < index) {
      handleScroll({ deltaY: -1, steps: index - changeableIndex });
    }
  };

  return (
    <div
      onWheel={handleScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={(e) => handleScroll(e, "touch")}
      className={stylesBack.background}
    >
      <div className={stylesBack.dots}>
        {refs.map((_, i) => (
          <div
            className={stylesBack.dotContainer}
            style={{ paddingRight: index === i ? "18px" : "20px" }}
            key={i}
            onClick={() => {
              scrollFromDots(i);
            }}
          >
            <span
              className={
                index === i ? stylesBack.activePageName : stylesBack.pageName
              }
            >
              {sectionNames[i]}
            </span>
            <span
              className={index === i ? stylesBack.activeDot : stylesBack.dot}
            ></span>
          </div>
        ))}
      </div>
      <animated.div style={section1Styles} className={stylesBack.content}>
        <Section1 handleGoToSignUp={handleGoToSignUp} isInView={index === 0} />
      </animated.div>

      <animated.div style={color1_1Styles} className={stylesBack.color1} />
      <animated.div style={color2_1Styles} className={stylesBack.color2} />

      <animated.div style={section2Styles} className={stylesBack.content}>
        <Section2 isInView={index === 1} />
      </animated.div>

      <animated.div style={color1_2Styles} className={stylesBack.color1} />
      <animated.div style={color2_2Styles} className={stylesBack.color2} />

      {/* <animated.div style={section3Styles} className={stylesBack.content}>
        <Section3 isInView={index === 2} />
      </animated.div>*/}
      <animated.div style={section3Styles} className={stylesBack.content}>
        <Section4 isInView={index === 2} />
      </animated.div>

      <animated.div style={color1_3Styles} className={stylesBack.color1} />
      <animated.div style={color2_3Styles} className={stylesBack.color2} />

      <animated.div style={section4Styles} className={stylesBack.content}>
        <Section5 isInView={index === 3} />
      </animated.div>
    </div>
  );
};

export default Users;
