import React, { useEffect, useState } from "react";
import styles from "./section4.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Loader } from '../../../assets/loader.svg'
import { API_URL } from "../../../utils/constants";
import { ReactComponent as UserCheck } from '../../../assets/user-check.svg'
import { ReactComponent as Eye } from '../../../assets/eye.svg'
import { ReactComponent as EyeOff } from '../../../assets/eye-off.svg'
// import ReCAPTCHA from 'react-google-recaptcha';


const initErrors = {
  name: "",
  email: "",
  password: "",
  passwordConfirm: "",
  reqError: "",
}

const Section4 = ({ isInView }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false)
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });

  const [errors, setErrors] = useState(initErrors);
  const [success, setSuccess] = useState(false);
  const [showPass, setShowPass] = useState(false);


  const handleInput = (e) => {
    setInputValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  function validateEmail(email) {
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.match(emailPattern)
  }

  const registration = () => {
    if (loading) {
      return
    }

    const allErrors = {}
    if (inputValues.password.length < 8) {
      allErrors.password = "Password must be at last 8 characters"
    } else if (inputValues.password !== inputValues.passwordConfirm) {
      allErrors.password = "Passwords don't match"
    }
    if (inputValues.firstName.length < 2 || inputValues.lastName.length < 2) {
      allErrors.name = "Firstname and Lastname must be at last 2 characters"
    }

    if (!validateEmail(inputValues.email)) {
      allErrors.email = "Please provide valid Email"
    }
    if (Object.values(allErrors).some(item => item)) {
      setErrors({ ...initErrors, ...allErrors })
      return
    } else {
      setErrors(initErrors)
    }

    const url = `${API_URL}/api/v1/users/signUp?redirectURL=${window.location.origin}/confirmEmail`
    setLoading(true)
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      params: { redirectURL: window.location.origin },
      body: JSON.stringify(inputValues)
    })
      .then(res => {
        return res.json()
      })
      .then((res) => {

        setLoading(false)
        if (res.errorMsg) {
          setErrors({ ...initErrors, reqError: res.errorMsg ? res.errorMsg : 'An error occuired during creation, please try again later or' })
          return
        }
        setSuccess(true)
      })
      .catch((error) => {
        console.log(error);
        setErrors({ ...initErrors, reqError: error.errorMsg ? error.errorMsg : 'An error occuired during creation, please try again later or' })
        setLoading(false)
      })

  };


  return (
    <div className={styles.containerFlex}>
      <div className={styles.container}>
        {success ? <div className={styles.success} >
          <UserCheck />
          <div className={styles.successTitle}>
            Email sent
          </div>
          <div className={styles.successText} >
            please check your Email ({inputValues.email}) to validate account.
          </div>
        </div>
          :
          <>
            <p>{t(`user.4.title`)}</p>
            <div className={styles.inputs}>
              <div className={styles.namesCont}>
                <input
                  name={"firstName"}
                  placeholder={t(`user.4.firstName`)}
                  onChange={(e) => handleInput(e)}
                />
                <input
                  name={"lastName"}
                  placeholder={t(`user.4.lastName`)}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              {errors.name && <p className={styles.error} >{errors.name}</p>}
              <input
                name={"email"}
                placeholder={t(`user.4.inp2`)}
                type="email"
                onChange={(e) => handleInput(e)}
              />
              {errors.email && <p className={styles.error} >{errors.email}</p>}
              <div className={styles.passCont} >
                <input
                  name={"password"}
                  placeholder={t(`user.4.inp3`)}
                  type={showPass ? "text" : "password"}
                  onChange={(e) => handleInput(e)}
                />
                <span onClick={()=>setShowPass(!showPass)}>
                  {showPass ? <EyeOff/> : <Eye />}
                </span>
              </div>
              <div className={styles.passCont} >
                <input
                  name={"passwordConfirm"}
                  placeholder={t(`user.4.inp4`)}
                  type={showPass ? "text" : "password"}
                  onChange={(e) => handleInput(e)}
                />
                <span onClick={()=>setShowPass(!showPass)}>
                  {showPass ? <EyeOff/> : <Eye />}
                </span>
              </div>
              {errors.password && <p className={styles.error} >{errors.password}</p>}
              {/* <ReCAPTCHA sitekey="" /> */}
              <button disabled={loading} onClick={registration}>
                <span>{t(`user.4.btn`)}</span>
                {loading && <Loader />}
              </button>
              {errors.reqError && <p className={styles.error} >{errors.reqError} contact us by email <a href="mailto:info@Rippletv.io"> info@Rippletv.io </a></p>}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Section4;
