import React, { useEffect, useState } from 'react'
import styles from './validateEmail.module.css'
import { ReactComponent as Loader } from '../../assets/loader.svg'
import { extractTokenAndEmailFromURL } from '../../utils/helpers'
import { API_URL } from '../../utils/constants'
import Lottie from "lottie-react";
import Tada from "./tada.json";
import { ReactComponent as UserCheck } from '../../assets/user-check.svg'
import { ReactComponent as Userx } from '../../assets/user-x.svg'

export default function ValideateEmail() {

    const [header, setHeader] = useState(0)
    const [res, setRes] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const cb = () => {
            const header = document.getElementById('header')
            setHeader(header?.clientHeight)
        }
        document.addEventListener('resize', cb)

        const data = extractTokenAndEmailFromURL(window.location.search)
        setLoading(true)

        fetch(`${API_URL}/api/v1/users/validateEmail`, {
            method: "POST",
            body: JSON.stringify({ email: data?.email }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${data?.token}`
            }
        }).then(res => {
            return res.json()
        }).then(res => {
            setLoading(false)
            if (res.status === 'failed') {
                if (res?.error?.name === 'JsonWebTokenError') {
                    setError('Link expired')
                    return
                }
                setError(res.errorMsg || 'An error occuired, please try again later')
                return
            }
            setRes(res)
        }).catch(err => {
            setLoading(false)
            setError(err.toString() || 'An error occuired, please try again later')
        })

        return () => {
            document.removeEventListener('resize', cb)
        }
    }, [])


    return (
        <div style={{ height: `calc(100vh - ${header + 80}px)` }} className={styles.background} >
            <div className={styles.container}>
                {res &&
                    <>
                        <div className={styles.anim}>
                            <Lottie animationData={Tada} loop={false} />
                        </div>
                        <div className={styles.valid}>
                            <UserCheck />
                            <div className={styles.validText}>
                                Email has been successfully validated.
                            </div>
                            <div className={styles.subtitleSuccess} >
                                Enjoy RippleTV on your devices.
                                <br />
                                don't forget to rate us :)
                            </div>

                        </div>
                    </>}
                {error && <div className={styles.error}>
                    <Userx />
                    {error}
                    <div>
                        {!error.includes('Link expired') && <div className={styles.subtitle}>
                            If this behavior repeats, please contact us at<a href="mailto:info@Rippletv.io"> info@Rippletv.io </a>.
                        </div>}
                    </div>
                </div>}
                {loading && <div className={styles.validating}>Validating Email <Loader /></div>}
            </div>
        </div>
    )
}
