import "./App.css";
import Users from "./components/users/Users";
import Provider from "./components/provider/Provider";
import { useTranslation } from "react-i18next";
import Header from "./components/Header";
import { useEffect, useState } from "react";
import Policy from "./components/policy/policy";
import ValideateEmail from "./components/ValideateEmail/ValideateEmail";
import Lottie from "lottie-react";
import VO from './vertical-orientation.json'
import PolicyRipplePlayer from "./components/policy-rippleplayer/policy";

function App() {
  const [page, setPage] = useState("user");
  const [showOverlay, setShowOverlay] = useState(false);

  const isPrivacy = window.location.pathname.includes('privacy-policy')
  const isPrivacyRipplePlayer = window.location.pathname.includes('rippleplayer/privacy-policy')
  const isConfirmEmail = window.location.pathname.includes('confirmEmail')

  useEffect(() => {
    const updateDimension = () => {
      setShowOverlay(window.innerWidth < 1000 && window.innerHeight < window.innerWidth )
    };

    updateDimension()
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  return (
    <div className="app">
      {showOverlay && <div className={'orientationChangeCont'}>
        <div className={'orientationChange'}>
          <div className="changeAnim" >
            <Lottie animationData={VO} loop={true} width={150} />
          </div>
          <p>Please change to portrait for better experience.</p>
        </div>
      </div>}
      <Header setPage={setPage} page={page}>
        {isConfirmEmail ? <ValideateEmail /> : isPrivacyRipplePlayer ? <PolicyRipplePlayer /> : isPrivacy ? <Policy /> : page === "user" ? <Users /> : <Provider />}
      </Header>
    </div>
  );
}

export default App;
