import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./section1.module.css";
import Img9 from "../../../assets/img9.png";
import Img10 from "../../../assets/img10.png";
import Iphone from "../../../assets/iphone.svg";
import { useTranslation } from "react-i18next";
import { animated, useSprings } from "@react-spring/web";

const Section1 = ({ isInView }) => {
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState(true);

  const configImg = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configTexts = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configLine = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
  };

  const springImages = useSprings(3, [
    { ...configImg, delay: 100 },
    { ...configImg, delay: 200 },
    { ...configImg, delay: 350 },
  ]);
  const springTexts = useSprings(2, [
    { ...configTexts, delay: 100 },
    { ...configTexts, delay: 200 },
  ]);
  const springLine = useSprings(2, [
    { ...configLine, delay: 100 },
    { ...configLine, delay: 200 },
  ]);

  //
  //

  // const [screenSize, setScreenSize] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = useMemo(() => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 930) {
  //     return {
  //       transform: ` scale(${screenSize.height / 930}) `,
  //       top: "-30px",
  //       // marginTop: `${-100}px`,
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   }
  // }, [screenSize, isInView]);

  const prop1 = {
    transform: ` scale(${window.innerHeight / 930}) `,
    top: "-30px",
  };
  const prop2 = {
    transform: ` scale(${window.innerHeight / 630}) `,
  };

  const styleResult =
    window.innerWidth > 1400 && window.innerHeight < 930
      ? prop1
      : window.innerWidth < 1400 && window.innerHeight < 630
      ? prop2
      : {};

      const [display, setDisplay] = useState(false)
      useEffect(()=>{
        if(isInView){
          setDisplay("inherit")
        } else {
          setTimeout(()=>{
            setDisplay("none")
          }, 600)
        }
      }, [isInView])

  return (
    <>
      <div className={styles.container} style={{...styleResult, display: display}}>
        <div className={styles.texts}>
          <div className={styles.pcTitle}>
            <animated.div className={styles.h2Container} style={springTexts[0]}>
              <h2>{t(`provider.1.title`)}</h2>
            </animated.div>
          </div>

          <div className={styles.pcLines}>
            <animated.div
              className={styles.firstLine}
              style={springLine[0]}
            ></animated.div>
            <animated.div
              className={styles.secondLine}
              style={springLine[1]}
            ></animated.div>
          </div>
          <div className={styles.mobileLines}>
            <animated.div
              className={styles.firstLine}
              style={springLine[0]}
            ></animated.div>
            <animated.div
              className={styles.secondLine}
              style={springLine[1]}
            ></animated.div>
          </div>

          <animated.div className={styles.longText} style={springTexts[1]}>
            <div className={styles.pcLongText1}>
              {readMore ? (
                <p>{t(`provider.1.longText`)}</p>
              ) : (
                <p>{t(`provider.1.longText`).substring(0, 186) + "..."}</p>
              )}
              <div className={styles.readMoreContainer}>
                {!readMore ? (
                  <p
                    className={styles.readMore}
                    onClick={() => {
                      setReadMore((prev) => !prev);
                    }}
                  >
                    Read more
                  </p>
                ) : (
                  <p
                    className={styles.readMore}
                    onClick={() => {
                      setReadMore((prev) => !prev);
                    }}
                  >
                    Skip
                  </p>
                )}
              </div>
            </div>
          </animated.div>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.movileTitle}>
            <animated.div
              className={styles.h2Container2}
              style={springTexts[0]}
            >
              <h2>{t(`provider.1.title`)}</h2>
            </animated.div>
          </div>
          <div className={styles.images}>
            <animated.div style={springImages[0]}>
              <img src={Img9} className={styles.Img9} alt="" />
            </animated.div>
            <animated.div style={springImages[1]}>
              <img src={Img10} className={styles.Img10} alt="" />
            </animated.div>
            <animated.div style={springImages[2]}>
              <img src={Iphone} className={styles.Iphone} alt="" />
            </animated.div>
          </div>
        </div>
      </div>
      <div className={styles.mobileLines}>
        <div className={styles.mobileLinesBottom}>
          <animated.div
            className={styles.firstLine}
            style={springLine[0]}
          ></animated.div>
          <animated.div
            className={styles.secondLine}
            style={springLine[1]}
          ></animated.div>
        </div>
      </div>
    </>
    //  </div>
  );
};

export default Section1;
