import React, { useEffect, useRef, useState } from "react";
import styles from "./section3.module.css";
import Img11 from "../../../assets/img11.png";
import Img12 from "../../../assets/img12.png";
import Img5 from "../../../assets/img5.png";
import Img6 from "../../../assets/img6.png";
import { useTranslation } from "react-i18next";
import { animated, useSprings } from "@react-spring/web";
import Section2 from "../section2/Section2";

const Section3 = ({ isInView }) => {
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState(false);

  const configImg = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configTexts = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configLine = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
  };
  const configLine2 = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
  };

  const springImages = useSprings(4, [
    { ...configImg, delay: 100 },
    { ...configImg, delay: 200 },
    { ...configImg, delay: 350 },
    { ...configImg, delay: 450 },
  ]);
  const springTexts = useSprings(2, [
    { ...configTexts, delay: 100 },
    { ...configTexts, delay: 200 },
  ]);
  const springLine = useSprings(2, [
    { ...configLine, delay: 100 },
    { ...configLine, delay: 200 },
  ]);
  const springLine2 = useSprings(3, [
    { ...configLine2, delay: 100 },
    { ...configLine2, delay: 200 },
  ]);

  useEffect(() => {
    if (window.innerWidth >= 700) {
      setReadMore(true);
    } else {
      setReadMore(false);
    }
  }, []);

  // const [screenSize, setScreenSize] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = () => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 900) {
  //     return {
  //       transform: ` scale(${screenSize.height / 900}) `,
  //       top: "-30px",
  //       // marginTop: `${-100}px`,
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   }
  // };

  const prop1 = {
    transform: ` scale(${window.innerHeight / 900}) `,
    top: "-30px",
  };
  const prop2 = {
    transform: ` scale(${window.innerHeight / 630}) `,
  };

  const styleResult =
    window.innerWidth > 1400 && window.innerHeight < 900
      ? prop1
      : window.innerWidth < 1400 && window.innerHeight < 630
      ? prop2
      : {};

      const [display, setDisplay] = useState(false)
      useEffect(()=>{
        if(isInView){
          setDisplay("inherit")
        } else {
          setTimeout(()=>{
            setDisplay("none")
          }, 600)
        }
      }, [isInView])

  return (
    <div className={styles.content} style={{...styleResult, display: display}}>
      {window.innerWidth <= 700 && <Section2 />}

      <div className={styles.mobileLines}>
        <animated.div
          className={styles.firstLine}
          style={springLine2[0]}
        ></animated.div>
        <animated.div
          className={styles.secondLine}
          style={springLine2[1]}
        ></animated.div>
      </div>
      <div className={styles.container}>
        <div className={styles.imagesContainer}>
          <div className={styles.mobileTitle}>
            <animated.div style={springTexts[0]}>
              <p className={styles.title}>{t(`provider.3.title`)}</p>
            </animated.div>
          </div>
          <div className={styles.images}>
            <animated.div style={springImages[0]}>
              <img src={Img11} className={styles.Img11} alt="" />
            </animated.div>
            <animated.div style={springImages[1]}>
              <img src={Img12} className={styles.Img12} alt="" />
            </animated.div>
            <animated.div style={springImages[2]}>
              <img src={Img5} className={styles.Img5} alt="" />
            </animated.div>
            <animated.div style={springImages[3]}>
              <img src={Img6} className={styles.Img6} alt="" />
            </animated.div>
          </div>
        </div>
        <div className={styles.texts}>
          <div className={styles.pcTitle}>
            <animated.div style={springTexts[0]}>
              <p className={styles.title}>{t(`provider.3.title`)}</p>
            </animated.div>
          </div>
          <div className={styles.lineContainer}>
            <animated.div
              className={styles.firstLine}
              style={springLine[0]}
            ></animated.div>
            <animated.div
              className={styles.secondLine}
              style={springLine[1]}
            ></animated.div>
          </div>
          <animated.div className={styles.textContainer} style={springTexts[1]}>
            {readMore ? (
              <p>{t(`provider.3.longText`)}</p>
            ) : (
              <p>{t(`provider.3.longText`).substring(0, 186) + "..."}</p>
            )}
            <div className={styles.readMoreContainer}>
              {!readMore ? (
                <p
                  className={styles.readMore}
                  onClick={() => {
                    setReadMore((prev) => !prev);
                  }}
                >
                  Read more
                </p>
              ) : (
                <p
                  className={styles.readMore}
                  onClick={() => {
                    setReadMore((prev) => !prev);
                  }}
                >
                  Skip
                </p>
              )}
            </div>
          </animated.div>
        </div>
      </div>
      <div className={`${styles.mobileLines} ${styles.mobileLinesBottom}`}>
        <animated.div
          className={styles.firstLine}
          style={springLine[0]}
        ></animated.div>
        <animated.div
          className={styles.secondLine}
          style={springLine[1]}
        ></animated.div>
      </div>
    </div>
  );
};

export default Section3;
