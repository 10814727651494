import React, { useEffect, useRef, useState } from "react";
import styles from "./section2.module.css";
import { ReactComponent as InfoIcon } from "../../../assets/info.svg";
import Img4 from "../../../assets/IphoneLogin.png";
import Img5 from "../../../assets/img5.png";
import Img6 from "../../../assets/img6.png";
import { useTranslation } from "react-i18next";
import { animated, useSprings } from "@react-spring/web";


const infoTexts = [
  "Experience real-time streaming of your favorite TV channels and never miss a moment with our Live Channels feature. Watch your preferred shows, news, sports, and entertainment content as they air. Worried about missing an episode? No problem! Our features allows you to access previously aired content, ensuring you can catch up on anything you might have missed.",
  "Get ready for a cinematic experience within our app. Explore our vast collection of movies from various genres, including action, romance, comedy, thriller, and more. With our Movie Section, you'll have access to an extensive library of films to choose from, providing endless entertainment options at your fingertips.",
  "Take control of your content by importing your own playlist. Whether it's a collection of personal videos, a curated selection of favorite channels, or custom content, our app allows you to effortlessly import and organize your playlist, ensuring a personalized viewing experience tailored to your preferences.",
  "Language should never be a barrier to enjoying great entertainment. Our app supports multiple languages, allowing you to select your preferred language for an enhanced viewing experience. Choose from a wide range of available languages, ensuring that you can enjoy content in a language that suits your preferences",
  "Our app is thoughtfully designed with everyone in mind. We prioritize user-friendliness, ensuring that individuals of all ages and technical abilities can navigate and enjoy our platform effortlessly. Whether you're a tech-savvy enthusiast or someone who is new to streaming services, our intuitive interface and easy-to-use features make accessing and enjoying your favorite content a seamless experience for users of all backgrounds.",
  "Our app offers robust subuser management capabilities, similar to Netflix's parental control features. Create and manage subuser accounts, allowing each member of your family to have their own viewing profile. With age restrictions and channel selection options, you can ensure that each subuser has access only to age-appropriate content and the channels you authorize.",
  "Take charge of your family's screen time with our app's daily duration and age restriction settings. Set time limits for each subuser's daily viewing duration to promote a healthy balance between entertainment and other activities. Additionally, tailor the app's content by applying age restrictions, ensuring that each subuser's viewing experience aligns with their appropriate age group.",
  "We understand the importance of maintaining a consistent brand identity. That's why our app offers full branding availability, allowing businesses, organizations, and content providers to customize the app to align with their unique brand image. You have the freedom to incorporate your logo, color scheme, and other brand elements throughout the app, providing a seamless and cohesive experience for your audience. Whether you're a broadcaster, media company, or content aggregator, our app can be tailored to reflect your brand's identity, reinforcing brand recognition and creating a cohesive brand experience for your users.",
]



const Section2 = ({ isInView }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const ref2 = useRef(null);

  // const animation = useSpring({
  //   opacity: isInView ? 1 : 0,
  //   transform: isInView ? "translateY(0)" : "translateY(50px)",
  // });

  // const springRef1 = useSpringRef();
  // const springRef2 = useSpringRef();
  // const springRef3 = useSpringRef();
  // const springRef4 = useSpringRef();

  const configImg = {
    from: { opacity: 0 },
    to: {
      opacity: isInView ? 1 : 0,
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configTexts = {
    from: { opacity: 0, width: 0, height: 0 },
    to: {
      opacity: isInView ? 1 : 0,
      width: isInView ? (window.innerWidth - 200) * 50 / 100 : 0,
      height: isInView ? (window.innerHeight - 200) : 0,
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configLine = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
  };

  const springImages = useSprings(4, [
    { ...configImg, delay: 700 },
    { ...configImg, delay: 200 },
    { ...configImg, delay: 350 },
    { ...configImg, delay: 450 },
  ]);
  const springTexts = useSprings(3, [
    { ...configTexts, delay: 900 },
    { ...configTexts, delay: 900 },
    { ...configTexts, delay: 300 },
  ]);
  const springLine = useSprings(4, [
    { ...configLine, delay: 100 },
    { ...configLine, delay: 200 },
    { ...configLine, delay: 300 },
    { ...configLine, delay: 400 },
  ]);


  const [info, setInfo] = useState({
    x: null,
    y: null,
    text: '',
    open: false
  })
  const handleMouseEnter = (e, i, showUp) => {
    const classes = document.getElementsByClassName(styles.feature)
    const el = classes[i-1]
    const y = el.offsetTop - 300
    setInfo({
      x: el.offsetTop + el.offsetHeight + 300 < window.innerHeight ? el.offsetLeft : el.offsetLeft + (i<5 ? -160 : 170),
      y: el.offsetTop + el.offsetHeight + 300 < window.innerHeight ? el.offsetTop + el.offsetHeight : el.offsetTop + (i<5 ? -50 : -210),
      text: infoTexts[i - 1],
      open: true
    })
    const infoClasses = document.getElementsByClassName(styles.info)
    const index = i<5 ? 0 : 1
    infoClasses[index].classList.add(styles.infoRotate)
  }

  const handleMoseLeave = (i) => {
    const infoClasses = document.getElementsByClassName(styles.info)
    const index = i<5 ? 0 : 1
    infoClasses[index].classList.remove(styles.infoRotate)
  }

  return (
    // <>
    <div className={styles.container} >
      <animated.div className={styles.leftFeatures} style={springTexts[0]}>
        <div  className={`${styles.feature} ${styles.feature1}`} >
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 1)} onMouseLeave={() => handleMoseLeave(1)}>
            <InfoIcon />
          </span>
          <span>
            Live Channels with{'\n'}TimeShift & CatchUp
          </span>
        </div>
        <div  className={`${styles.feature} ${styles.feature2}`} >
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 2)} onMouseLeave={() => handleMoseLeave(2)}>
            <InfoIcon />
          </span>
          <span>
            Video on Demand{'\n'}(Movies Section)
          </span>

        </div>
        <div  className={`${styles.feature} ${styles.feature3}`} >
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 3)} onMouseLeave={() => handleMoseLeave(3)}>
            <InfoIcon />
          </span>
          <span>
            Import Your{'\n'}Own Playlist
          </span>
        </div>
        <div className={`${styles.feature} ${styles.feature4}`} >
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 4, true)} onMouseLeave={() => handleMoseLeave(4)}>
            <InfoIcon />
          </span>
          <span>
            Language{'\n'}Options
          </span>
        </div>
        <div style={{
          top: `${info.y}px`,
          left: `${info.x}px`,
        }}
          className={styles.info}
        >
          {info.text}
        </div>
      </animated.div>
      <animated.div className={styles.imageContainer} style={springImages[0]}>
        <img src={Img4} className={styles.Phone} alt="img4" />
      </animated.div>
      <animated.div className={styles.rightFeatures} style={springTexts[1]}>
        <div  className={`${styles.feature} ${styles.feature5}`} >
          <span>
            Designed for{'\n'}Everybody
          </span>
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 5)} onMouseLeave={() => handleMoseLeave(5)}>
            <InfoIcon />
          </span>
        </div>
        <div  className={`${styles.feature} ${styles.feature6}`} >
          <span>
            Subuser Management &{'\n'}Parental Control
          </span>
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 6)} onMouseLeave={() => handleMoseLeave(6)}>
            <InfoIcon />
          </span>
        </div>
        <div  className={`${styles.feature} ${styles.feature7}`} >
          <span>
            Daily Duration &{'\n'}Age Restrictions
          </span>
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 7)} onMouseLeave={() => handleMoseLeave(7)}>
            <InfoIcon />
          </span>
        </div>
        <div  className={`${styles.feature} ${styles.feature8}`} >
          <span>
            Full Branding{'\n'}Availability
          </span>
          <span className={styles.infoIcon} onMouseEnter={(e) => handleMouseEnter(e, 8, true)} onMouseLeave={() => handleMoseLeave(8)}>
            <InfoIcon />
          </span>
        </div>
        <div style={{
          top: `${info.y}px`,
          left: `${info.x}px`,
        }}
          className={styles.info}
        >
          {info.text}
        </div>
      </animated.div>
    </div>
  );
};

export default Section2;
