import React, { useEffect, useState } from 'react'
import styles from './policy.module.css'
export default function PolicyRipplePlayer() {
    const [header, setHeader] = useState(0)
    useEffect(() => {
        const cb = () => {
            const header = document.getElementById('header')
            setHeader(header?.clientHeight)
        }
        document.addEventListener('resize', cb)
        return () => {
            document.removeEventListener('resize', cb)
        }
    }, [])
    return (
        <div style={{ height: `calc(100vh - ${header + 80}px)` }} className={styles.background} >
            <div style={{ width: '1010px' }} className={styles.inner} >

                <h1>RipplePlayer Privacy Policy</h1>
                <br/>
                <p><strong>Effective Date:</strong> 10/12/2023</p>
                <br/>
                <p>This Privacy Policy outlines how RipplePlayer ("we," "our," or "the App") collects, uses, and protects user information. It is designed to inform you about our practices regarding the collection, use, and disclosure of personal information when using our IPTV M3U player app.</p>
                <br/>
                <h2>1. Information We Collect</h2>
                <br/>
                <p><strong>1.1. User-Provided Information:</strong> The App allows users to add M3U playlists to access and watch channels. The playlists and channel information are provided by users and stored locally on the device. We do not have access to this information, and it is not shared with us or third parties.</p>
                <br/>
                <p><strong>1.2. Advertisements:</strong> The App may display ads to support its free availability. These ads may be personalized based on user data, such as location or device type, but no personally identifiable information is collected for this purpose.</p>
                <br/>
                <h2>2. In-App Purchases</h2>
                <br/>
                <p>The App offers an in-app purchase option for $1.99 per month, allowing users to remove ads. Payment processing for in-app purchases is handled by the app store provider, and we do not have access to user payment details.</p>
                <br/>
                <h2>3. User Privacy</h2>
                <br/>
                <p>We take user privacy seriously and do not collect personal information beyond what is necessary for the core functionality of the App. We do not store or share user playlists, channel information, or any other user data.</p>
                <br/>
                <h2>4. Data Security</h2>
                <br/>
                <p>We implement reasonable security measures to protect user data. However, it is essential to understand that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the absolute security of information.</p>
                <br/>
                <h2>5. Changes to Privacy Policy</h2>
                <br/>
                <p>We may update this Privacy Policy to reflect changes in the App or applicable regulations. Users will be informed of any material changes through the App, and we recommend reviewing this policy periodically.</p>
                <br/>
                <h2>6. Contact Us</h2>
                <br/>
                <p>If you have questions or concerns regarding this Privacy Policy or the App's privacy practices, please contact us at <a href="mailto: info@rippletv.io">info@RippleTV.io</a>.</p>

            </div>
        </div>
    )
}
