import React, { useEffect, useRef, useState } from "react";
import styles from "./section4.module.css";
import Img13 from "../../../assets/img13.png";
import Img14 from "../../../assets/img14.png";
import { useTranslation } from "react-i18next";
import { animated, useSprings } from "@react-spring/web";

const Section4 = ({ isInView }) => {
  const { t } = useTranslation();

  const configImg = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configTexts = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 1, tension: 280, friction: 50 },
  };

  const configLine = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
  };
  const configLineFooterLeft = {
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(-100%)",
    },
    config: { mass: 2, tension: 50, friction: 20 },
  };
  const configLineFooterRight = {
    from: { opacity: 0, transform: "translateX(100%)" },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateX(0%)" : "translateX(100%)",
    },
    config: { mass: 3, tension: 60, friction: 30 },
  };

  const springImages = useSprings(2, [
    { ...configImg, delay: 100 },
    { ...configImg, delay: 200 },
  ]);
  const springTexts = useSprings(2, [
    { ...configTexts, delay: 100 },
    { ...configTexts, delay: 200 },
  ]);
  const springLine = useSprings(2, [
    { ...configLine, delay: 100 },
    { ...configLine, delay: 200 },
  ]);
  const springLineFooterLeft = useSprings(1, [
    { ...configLineFooterLeft, delay: 300 },
  ]);
  const springLineFooterRight = useSprings(1, [
    { ...configLineFooterRight, delay: 300 },
  ]);

  // const [screenSize, setScreenSize] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  // const scale = () => {
  //   if (isInView && screenSize.width > 1400 && screenSize.height < 900) {
  //     return {
  //       transform: ` scale(${screenSize.height / 900}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   } else if (isInView && screenSize.width < 1400 && screenSize.height < 630) {
  //     return {
  //       transform: ` scale(${screenSize.height / 630}) `,
  //       // marginTop: `${-100}px`,
  //     };
  //   }
  // };

  const prop1 = {
    transform: ` scale(${window.innerHeight / 900}) `,
  };
  const prop2 = {
    transform: ` scale(${window.innerHeight / 630}) `,
  };

  const styleResult =
    window.innerWidth > 1400 && window.innerHeight < 900
      ? prop1
      : window.innerWidth < 1400 && window.innerHeight < 630
      ? prop2
      : {};
      const [display, setDisplay] = useState(false)
      useEffect(()=>{
        if(isInView){
          setDisplay("inherit")
        } else {
          setTimeout(()=>{
            setDisplay("none")
          }, 600)
        }
      }, [isInView])

  return (
    <div className={styles.content} style={{display: display}}>
      <div
        className={styles.container}
        style={{ ...styleResult, top: "-50px" }}
      >
        <div className={styles.texts}>
          <div className={styles.pcTitle}>
            <animated.div
              className={styles.titleContainer}
              style={springTexts[0]}
            >
              <p className={styles.title}>{t(`provider.4.title`)}</p>
            </animated.div>
          </div>
          <animated.div
            className={styles.firstLine}
            style={springLine[0]}
          ></animated.div>
          <animated.div
            className={styles.secondLine}
            style={springLine[1]}
          ></animated.div>
          <animated.div className={styles.textContainer} style={springTexts[1]}>
            <p>{t(`provider.4.longText`)}</p>
          </animated.div>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.mobileTitle}>
            <animated.div
              className={styles.titleContainer}
              style={springTexts[0]}
            >
              <p className={styles.title}>{t(`provider.4.title`)}</p>
            </animated.div>
          </div>
          <div className={styles.images}>
            <animated.div style={springImages[0]}>
              <img src={Img13} className={styles.Img13} alt="Img13" />
            </animated.div>
            <animated.div style={springImages[1]}>
              <img src={Img14} className={styles.Img14} alt="img8" />
            </animated.div>
          </div>
        </div>
      </div>
      <div className={styles.footer} style={{ ...styleResult, bottom: "60px" }}>
        <animated.div
          className={styles.leftLne}
          style={springLineFooterLeft[0]}
        ></animated.div>
        <div className={styles.footerTextContainer}>
          <p className={styles.pcTitle}>{t(`provider.4.footer`)}</p>
          <p className={styles.mobileTitle}>{t(`provider.4.mobileFooter`)}</p>
        </div>
        <animated.div
          className={styles.rightLine}
          style={springLineFooterRight[0]}
        ></animated.div>
      </div>
    </div>
  );
};

export default Section4;
